<!--
 * @Author: your name
 * @Date: 2020-10-21 13:49:50
 * @LastEditTime: 2021-07-22 20:14:39
 * @LastEditors: yangliao
 * @Description: 立即缴费页面
 * @FilePath: /netHallOfficialAccounts/src/views/QueryPay/PaymentPage.vue
-->
<template>
  <div v-if="dataInfo" class="paymentPage">
    <div class="paymentPage-header">
      <span v-if="state">
        检测到该用户有 {{ dataInfo.orders && dataInfo.orders.length }} 笔未缴账单，请缴纳后办理！
      </span>
      <span v-else>
        检测到该用户有 {{ dataInfo.orders && dataInfo.orders.length }} 笔未缴账单
      </span>
      <p>¥ {{ isActual(dataInfo.arrearsAmount) }}</p>
    </div>
    <div class="paymentPage-content">
      <div class="paymentPage-title">
        <div class="wuser-name">
          <div>{{ dataInfo.wuserName }}</div>
          <div>户号：{{ dataInfo.wuserNo }}</div>
        </div>
        <p class="van-multi-ellipsis--l3">{{ dataInfo.wuserAddress }}</p>
      </div>
      <div class="paymentPage-card">
        <div class="user-card">
          <van-cell v-for="(item, index) in dataInfo.orders" :key="index">
            <div class="user-card-info">
              <div class="user-card-info-checkbox">
                <van-checkbox
                  v-model="item.checked"
                  :name="index"
                  icon-size="18px"
                  @click="getCheckboxValue"
                >
                  <div class="ucic-mouth">{{ item.chargeMonth }}</div>
                  <div class="ucic-name">{{ item.chargeName }}</div>
                </van-checkbox>
              </div>
              <div class="user-card-cost" @click="feeDetails(item.billId)">
                <div class="cost-money">
                  {{ isActual(item.waterFee) }}
                </div>
                <van-icon name="arrow" color="#CDCDCD" size="16px" />
              </div>
            </div>
          </van-cell>
        </div>
      </div>
    </div>

    <div class="paymentPage-foot">
      <van-cell center>
        <template #title>
          <div class="paymentPage-foot-mouth">还需支付：¥{{ isActual(totalPay) }}</div>
          <div class="paymentPage-foot-name">
            <van-checkbox
              v-model="dataInfo.depositType"
              shape="square"
              icon-size="15px"
              :disabled="dataInfo.isPrestore"
              @click="getCheckboxValue"
            >
              预存可抵：¥{{ isActual(dataInfo.depositAmount) }}
            </van-checkbox>
          </div>
        </template>
        <template #default>
          <div>
            <van-button
              class="paymentPageno-repeat 0"
              type="info"
              :disabled="payBtnFlag"
              @click="clickPaymentOrder"
              v-if="payBtnFlag"
            >
              一键缴费
            </van-button>
              <div v-else>
                <wx-open-launch-weapp
                    id="launch-btn"
                    username="gh_aceb9bd462ab"
                    :path="'main/pages/nativeindex/nativeindex?fr=p&pg=charge&ct=371500&sv=1&ch=37150001910103&bk=' +dataInfo.wuserNo"
                    style="width:88px;height:40px"
                  >
                    <script type="text/wxtag-template">
                      <style>.test-btn-charge {width: 100%;height: 100%;background: #0a79ec;color: #fff;font-family: PingFangSC-Medium;font-size: 14px;border: none;line-height: 38px;z-index: 3;outline: none;border:0px;border-radius:8px}</style>
                      <button class="test-btn-charge">一键缴费</button>
                    </script>
                  </wx-open-launch-weapp>
              </div>
          </div>
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import { selectByWaterUserNo, paymentOrder } from '../../api/home';
import { formatTime } from '@/utils/tools';
import NP from 'number-precision';
import { isAmount } from '@/utils/validator';
import { Dialog } from 'vant';

export default {
  name: 'PaymentPage',
  data() {
    return {
      payList: [],
      totalPay: 0,
      preMoney: 100,
      checked: false,
      dataInfo: null,
      payBtnFlag: false,
      wuserNo: this.$route.query.wuserNo,
      state: '',
    };
  },
  computed: {
    isActual() {
      return (val) => {
        return isAmount(val);
      };
    },
  },
  mounted() {
    this.selectByWaterUserNo();
    if (this.$route.query && this.$route.query.state) {
      this.state = this.$route.query.state;
    }
  },
  methods: {
    // 获取页面数据
    async selectByWaterUserNo() {
      let wuserNo = this.wuserNo;
      const { status, resultData } = await selectByWaterUserNo(wuserNo);
      if (status === 'complete') {
        resultData.finalPaymentDate = formatTime(resultData.finalPaymentDate);
        // 判断余额是否大于0
        if (!isNaN(Number(resultData.depositAmount)) && Number(resultData.depositAmount) > 0) {
          resultData.isPrestore = false;
          resultData.depositType = true;
        } else {
          resultData.isPrestore = true;
          resultData.depositType = false;
        }
        if (resultData.depositType && !isNaN(Number(resultData.depositAmount))) {
          let actual = NP.minus(Number(resultData.arrearsAmount), Number(resultData.depositAmount));
          if (actual > 0) {
            this.totalPay = actual;
          } else {
            this.totalPay = 0;
          }
        } else {
          this.totalPay = resultData.arrearsAmount;
        }
        this.dataInfo = resultData;
      }
    },
    feeDetails(billId) {
      this.$router.push({
        name: 'FeeDetails',
        path: `/QueryPay/FeeDetails`,
        query: {
          billId: billId,
          wuserNo: this.dataInfo.wuserNo,
        },
      });
    },
    getCheckboxValue() {
      let currentTotal = 0;
      if (this.dataInfo.orders && this.dataInfo.orders.length > 0) {
        this.payBtnFlag = !this.dataInfo.orders.some((item) => item.checked);
        this.dataInfo.orders.map((item) => {
          if (item.checked) {
            currentTotal = NP.plus(currentTotal, Number(item.waterFee));
          }
        });
      }
      // 判断是否置灰按钮
      this.payBtnFlag = !this.dataInfo.orders.some((item) => item.checked);
      if (!isNaN(Number(this.dataInfo.depositAmount)) && Number(this.dataInfo.depositAmount) > 0) {
        this.dataInfo.isPrestore = this.payBtnFlag;
      }
      // 如果选中了预存可扣，则用【当前的还需支付 - 预存可扣 = 最后还需支付的】
      if (this.dataInfo.depositType && !isNaN(Number(this.dataInfo.depositAmount))) {
        let actual = NP.minus(Number(currentTotal), Number(this.dataInfo.depositAmount));
        if (actual > 0) {
          this.totalPay = actual;
        } else {
          this.totalPay = 0;
        }
      } else {
        this.totalPay = currentTotal;
      }
    },
    // 一键缴费
    async clickPaymentOrder() {
      if (!isNaN(Number(this.totalPay)) && Number(this.totalPay) <= 0) {
        Dialog.alert({
          title: '无需支付',
          message: '余额大于缴费金额，会自动抵扣!',
          confirmButtonColor: '#277FF2',
        });
        return;
      }
      let billId = [];
      this.dataInfo &&
        this.dataInfo.orders.map((item, index) => {
          if (item.checked) {
            billId.push(item.billId);
          }
        });
      let arg = [
        {
          wuserNo: this.dataInfo.wuserNo,
          depositType: this.dataInfo.depositType,
          billId: billId,
        },
      ];

      // this.$router.push({
      //   path: '/charge',
      // });

      // const { status, resultData } = await paymentOrder(arg);
      // if (status === 'complete') {
      //   if (resultData) {
      //     if (Object.keys(resultData).length === 0) {
      //       if (typeof WeixinJSBridge === 'undefined') {
      //         if (document.addEventListener) {
      //           document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
      //         } else if (document.attachEvent) {
      //           document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
      //           document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
      //         }
      //       }
      //     } else {
      //       this.onBridgeReady(resultData);
      //     }
      //   } else {
      //     this.$router.push({
      //       name: 'PaymentResult',
      //       path: '/QueryPay/PaymentResult',
      //       query: { name: 'QueryPay' }
      //     });
      //   }
      // }
    },
    // 微信支付：
    onBridgeReady(params) {
      var that = this;
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.appId, //公众号名称，由商户传入
          timeStamp: params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, //支付签名随机串，不长于 32 位
          package: params.packageValue, //统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign, //支付签名
        },
        function(res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            console.log('成功', res.err_msg);
            that.$router.push({
              name: 'PaymentResult',
              path: '/QueryPay/PaymentResult',
              query: {
                name: 'QueryPay',
              },
            });
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            console.log('失败', res.err_msg);
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.paymentPage {
  .paymentPage-header {
    height: 250px;
    overflow: hidden;
    background: url('../../assets/background/backdrop.png') no-repeat 0 0;
    background-size: 100% auto;
    padding-left: 16px;
    padding-top: 20px;
    span {
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: -0.42px;
    }
    p {
      margin: 0px;
      font-family: PingFangSC-Semibold;
      font-size: 38px;
      color: #ffffff;
      letter-spacing: 0;
      padding-top: 6px;
    }
    .txtInfo {
      display: flex;
      align-items: center;
      color: #c2d9ff;
      font-size: 12px;
      padding-top: 6px;
      &-describe {
        margin-left: 6px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);
      }
    }
  }
  .paymentPage-content {
    overflow: hidden;
    margin: -168px 16px 76px 16px;
    background: #ffffff;
    border-radius: 8px;

    .paymentPage-title {
      padding: 16px;
      .wuser-name {
        display: flex;
        flex-wrap: wrap;
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 700;
        :first-child {
          margin-right: 8px;
        }
      }
      p {
        margin: 0px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        line-height: 22px;
      }
    }
    .paymentPage-card {
      .user-card {
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 5px;
        box-shadow: 0 6px 16px 0 rgba(63, 70, 79, 0.12);
        .user-card-info {
          display: flex;
          .user-card-checkbox {
            display: flex;
            justify-content: center;
            .ucic-mouth {
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
            }
            .ucic-name {
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.45);
            }
          }
          .user-card-cost {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
            .cost-money {
              font-family: PingFangSC-Regular;
              margin-right: 6px;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.85);
              letter-spacing: 0;
              line-height: 24px;
              text-align: right;
              div {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.45);
                letter-spacing: 0;
                text-align: right;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
  .paymentPage-foot {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #f0f0f0;
    &-mouth {
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
    /deep/ .paymentPage-foot-name .van-checkbox__label {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
    }
    &-pay {
      background: #0a79ec;
      border-radius: 8px;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: #ffffff;
      // width: 88px;
      height: 40px;
    }
    .paymentPageno-repeat {
      background: #0a79ec;
      border-radius: 8px;
      font-family: PingFangSC-Medium;
      font-size: 14px;
      color: #ffffff;
      //   width: 88px;
      height: 40px;
    }
  }
}
</style>
